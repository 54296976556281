import styled, { css } from "styled-components"

const media = {
  mobile: (...args) =>
    css`
      @media only screen and (max-width: 767px) {
        ${css(...args)};
      }
    `,
  desktop: (...args) =>
    css`
      @media only screen and (min-width: 768px) {
        ${css(...args)};
      }
    `,
  xsOnly: (...args) =>
    css`
      @media only screen and (max-width: 575px) {
        ${css(...args)};
      }
    `,
  sm: (...args) =>
    css`
      @media only screen and (min-width: 576px) {
        ${css(...args)};
      }
    `,
  smOnly: (...args) =>
    css`
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        ${css(...args)};
      }
    `,
  md: (...args) =>
    css`
      @media only screen and (min-width: 768px) {
        ${css(...args)};
      }
    `,
  mdOnly: (...args) =>
    css`
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        ${css(...args)};
      }
    `,
  lg: (...args) =>
    css`
      @media only screen and (min-width: 992px) {
        ${css(...args)};
      }
    `,
  lgOnly: (...args) =>
    css`
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        ${css(...args)};
      }
    `,
  xl: (...args) =>
    css`
      @media only screen and (min-width: 1200px) {
        ${css(...args)};
      }
    `,
  print: (...args) =>
    css`
      @media only print {
        ${css(...args)};
      }
    `,
}

export const Media = Object.keys(media).reduce((acc, key) => {
  const name = key.charAt(0).toUpperCase() + key.slice(1)
  const Match = styled.div`
    display: none;
    ${media[key]`
      display: initial;
    `};
  `

  Match.displayName = `Media.${name}`
  acc[name] = Match
  const NotMatch = styled.div`
    ${media[key]`
      display: none;
    `};
  `

  NotMatch.displayName = `Media.Not${name}`
  acc[`Not${name}`] = NotMatch

  return acc
}, {})

export default media
