// Styleguide: https://www.figma.com/file/e4GKVrlx2hL3FRiNgJlfoxH1/Styleguide-Gamma.nl?node-id=2880%3A2097

import { DefaultTheme } from "styled-components"

// see: https://material.io/design/color/the-color-system.html#tools-for-picking-colors
// styleguide colors mapped towards the material design approach
// as it will remain more flexible over time and allows for greater extension.
/** Brand colors  */
const black900 = "#000"
const white50 = "#FFF"
const blue900 = "#003878" // styleguide: GAMMA
const blue900o20 = "rgba(0, 56, 120, 0.2)" // blue900 @ 20% transparcy
const darkblue900 = "#000545"

/** Greyscale */
const grey50 = "#F0F2F6" // styleguide: grey 1
const grey200 = "#A1B5C5" // styleguide: grey 2
const grey400 = "#7D98AA" // styleguide: grey 3
const grey500 = "#6D818D" // styleguide: grey 4
const grey700 = "#51646F" // styleguide: grey 5
const grey800 = "#36444D" // styleguide: grey 6

/** Call to action */
const green500 = "#74C200" // styleguide: CTA and Validation
const green600 = "#69AA0A" // styleguide: CTA+

/** Messaging and validations */
const red700 = "#E55026" // styleguide: Error
const red900 = "#CC370D" // styleguide: Error+

/** Specific brand colors, do not randomly use */
const orange900 = "#FF7300"

/** Foundations spacing */
const gutterSmall = 5
const gutter = 10
const gutterMedium = 15
const gutterLarge = 30
const gutterXL = 50
const gutterXXL = 70
const gridGap = 20

const Theme: DefaultTheme = {
  name: "gamma_nl",
  domain: "gamma.nl",
  gamma: true,
  brandName: "GAMMA",
  contentfulSpaceId: "7k5qhlbm8iou",
  contentfulToken:
    "264fa5643e4db3af05892ce1cec30e1880b98e082f9e0e3074590b6d67c508a7",
  paths: {
    termsAndConditions: "algemene-voorwaarden",
    disclaimer: "klantenservice/voorwaarden-verantwoordelijkheid/disclaimer",
    privacy: "klantenservice/veiligheid-privacy/privacy",
    responsibleDisclosure:
      "klantenservice/veiligheid-privacy/responsible-disclosure",
    returnRequirements:
      "klantenservice/ruilen-retourneren/retour-online-aankoop",
    customerservice: "klantenservice",
  },
  formulaLoyaltyCardName: "GAMMA Voordeelpas",
  loyaltyPointSlug: "Punten",
  loyaltyPointsOverview: "puntenoverzicht",
  loyaltyCardName: "GAMMA Voordeelpas",
  loyaltyCardSlug: "gamma-voordeelpas",
  giftcardName: "Cadeaupas",
  giftcardSlug: "cadeaupas",
  storeName: "bouwmarkt",
  storesName: "Bouwmarkten",
  storesSlug: "bouwmarkten",
  adviceName: "Klusadvies",
  adviceSlug: "klusadvies",
  serviceName: "Klantenservice",
  serviceSlug: "klantenservice",
  servicePhone: "088 – 010 86 10",
  servicePhoneLink: "tel:+31880108610",
  promoName: "Actie",
  promoSlug: "aanbiedingen-en-acties",
  baseFont: "Overpass, sans-serif",
  brandFont: "Overpass, sans-serif",
  serifFont: "Georgia, serif",
  borderRadius: 8,
  primaryBackgroundColor: white50,
  secondaryBackgroundColor: grey50,
  pageBackgroundColor: grey50,
  pageBackgroundColorTransparent: `${grey50}00`,
  footerBackgroundColor: grey50,
  brandColor: blue900,
  brandColorHighlight: blue900o20,
  brandColorSecondary: white50,
  linkColor: blue900,
  linkHoverColor: blue900,
  linkHighlightColor: blue900o20,
  buttonColor: blue900,
  buttonFocusBorderColor: green600,
  buttonFocusColor: darkblue900,
  buttonBorderRadius: 300,
  buttonHighlightColor: blue900o20,
  primaryButtonColor: green500,
  progressColor: green500,
  progressBackgroundColor: grey50,
  secondaryButtonColor: white50,
  secondaryButtonTextColor: blue900,
  secondaryButtonFocusColor: grey50,
  dangerButtonColor: red700,
  dangerButtonFocusColor: red900,
  inputBackgroundColor: white50,
  badgeColor: grey800,
  badgeBackgroundColor: grey200,
  notificationColor: green500,
  errorColor: red700,
  successColor: green500,
  mutedColor: grey700,
  borderColor: grey200,
  dividerColor: grey200,
  titleColor: black900,
  textColor: black900,
  iconColor: grey500,
  secondaryTextColor: grey700,
  searchPromotionColor: orange900,
  selectionBackground: "rgba(0, 147, 216, 0.25)",
  formInputFloatingLabelBackgroundColor: grey50,
  formInputFloatingLabelTextColor: grey700,
  formInputBorderColor: grey500,
  formInputBorderFocussedColor: blue900,
  formInputPlaceholderColor: grey500,
  formInputTextColor: black900,
  neutral50: grey50,
  neutral200: grey200,
  neutral400: grey400,
  neutral500: grey500,
  neutral700: grey700,
  neutral800: grey800,
  gutterSmall,
  gutter,
  gutterMedium,
  gutterLarge,
  gutterXL,
  gutterXXL,
  gridGap,
  fonts: [
    {
      family: "Overpass",
      file: "Overpass-Regular.woff",
      woff2file: "Overpass-Regular.woff2",
    },
    {
      family: "Overpass",
      file: "Overpass-ExtraBold.woff",
      weight: "bold",
      woff2file: "Overpass-ExtraBold.woff2",
    },
    {
      family: "Overpass",
      file: "Overpass-Italic.woff",
      style: "italic",
      woff2file: "Overpass-Italic.woff2",
    },
  ],
}

export default Theme
