// see: https://material.io/design/color/the-color-system.html#tools-for-picking-colors
// styleguide colors mapped towards the material design approach
// as it will remain more flexble over time and allows for greater extension.

import { DefaultTheme } from "styled-components"

/** Brand colors  */
const black900 = "#000"
const white50 = "#FFF"
const blue900 = "#003878" // styleguide: GAMMA
const blue900o20 = "rgba(0, 56, 120, 0.2)" // blue900 @ 20% transparcy
const darkblue900 = "#000545"

/** Greyscale */
const grey50 = "#F0F2F6" // styleguide grey 1
const grey200 = "#A1B5C5" // styleguide grey 2
const grey400 = "#7D98AA" // styleguide grey 3
const grey500 = "#6D818D" // styleguide grey 4
const grey700 = "#51646F" // styleguide grey 5
const grey800 = "#36444D" // styleguide grey 6

/** Messaging and validation */
const red700 = "#E55026" // styleguide: Error
const red900 = "#B93A16" // styleguide: Error+

/** Call to action */
const green500 = "#1DB954" // styleguide: CTA and Validation
const green700 = "#13883C" // styleguide: CTA+

/** Specific brand colors, do not randomly use */
const orange900 = "#FF7300"

/** Foundations spacing */
const gutterSmall = 5
const gutter = 10
const gutterMedium = 15
const gutterLarge = 30
const gutterXL = 50
const gutterXXL = 70
const gridGap = 20

const Theme: DefaultTheme = {
  name: "gamma_be",
  domain: "gamma.be",
  gamma: true,
  brandName: "GAMMA",
  contentfulSpaceId: "2oqwvxysf41a",
  contentfulToken:
    "bdbdeba23f7ce4133a995c715a38bb2e161163a579cb925a27a2d3f4e44c9e76",
  paths: {
    termsAndConditions:
      "klanteninformatie/veiligheid-privacy/algemene-voorwaarden",
    disclaimer: "klanteninformatie/veiligheid-privacy/disclaimer",
    privacy: "klanteninformatie/veiligheid-privacy/privacy-statement",
    responsibleDisclosure:
      "klanteninformatie/veiligheid-privacy/responsible-disclosure",
    returnRequirements:
      "klanteninformatie/ruilen-retourneren/retourneren-online-aankoop",
    customerservice: "klanteninformatie",
  },
  formulaLoyaltyCardName: "GAMMAplus",
  loyaltyPointSlug: "Punten",
  loyaltyPointsOverview: "puntenoverzicht",
  loyaltyCardName: "GAMMAplus kaart",
  loyaltyCardSlug: "gamma-plus-kaart",
  giftcardName: "Cadeaukaart",
  giftcardSlug: "cadeaukaart",
  storeName: "winkel",
  storesName: "Winkels",
  storesSlug: "winkels-en-openingsuren",
  adviceName: "Doe-het-zelf advies",
  adviceSlug: "doe-het-zelf",
  serviceName: "Services",
  serviceSlug: "klanteninformatie",
  servicePhone: "0800 62 610",
  servicePhoneLink: "tel:+3280062610",
  promoName: "Promo",
  promoSlug: "promo/acties",
  baseFont: "Overpass, sans-serif",
  brandFont: "Overpass, sans-serif",
  serifFont: "Georgia, serif",
  borderRadius: 8,
  primaryBackgroundColor: white50,
  secondaryBackgroundColor: grey50,
  pageBackgroundColor: grey50,
  pageBackgroundColorTransparent: `${grey50}00`,
  footerBackgroundColor: grey50,
  brandColor: blue900,
  brandColorHighlight: blue900o20,
  brandColorSecondary: white50,
  linkColor: blue900,
  linkHoverColor: blue900,
  linkHighlightColor: blue900o20,
  buttonColor: blue900,
  buttonFocusBorderColor: green700,
  buttonFocusColor: darkblue900,
  buttonHighlightColor: blue900o20,
  buttonBorderRadius: 300,
  badgeBackgroundColor: grey200,
  primaryButtonColor: green500,
  progressColor: green500,
  progressBackgroundColor: grey200,
  secondaryButtonColor: white50,
  secondaryButtonTextColor: blue900,
  secondaryButtonFocusColor: grey50,
  dangerButtonColor: red700,
  dangerButtonFocusColor: red900,
  inputBackgroundColor: white50,
  badgeColor: grey800,
  notificationColor: green500,
  errorColor: red700,
  successColor: green500,
  mutedColor: grey500,
  borderColor: grey200,
  dividerColor: grey200,
  titleColor: black900,
  textColor: black900,
  iconColor: grey500,
  secondaryTextColor: grey700,
  searchPromotionColor: orange900,
  selectionBackground: "rgba(0, 147, 216, 0.25)",
  formInputFloatingLabelBackgroundColor: grey50,
  formInputFloatingLabelTextColor: grey800,
  formInputBorderColor: grey500,
  formInputBorderFocussedColor: blue900,
  formInputPlaceholderColor: grey500,
  formInputTextColor: black900,
  neutral50: grey50,
  neutral200: grey200,
  neutral400: grey400,
  neutral500: grey500,
  neutral700: grey700,
  neutral800: grey800,
  gutterSmall,
  gutter,
  gutterMedium,
  gutterLarge,
  gutterXL,
  gutterXXL,
  gridGap,
  fonts: [
    {
      family: "Overpass",
      file: "Overpass-Regular.woff",
      woff2file: "Overpass-Regular.woff2",
    },
    {
      family: "Overpass",
      file: "Overpass-ExtraBold.woff",
      weight: "bold",
      woff2file: "Overpass-ExtraBold.woff2",
    },
    {
      family: "Overpass",
      file: "Overpass-Italic.woff",
      style: "italic",
      woff2file: "Overpass-Italic.woff2",
    },
  ],
}

export default Theme
