import styled from "styled-components"

import { media } from "@intergamma/theme"

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const ListItem = styled.li`
  position: relative;
  margin-bottom: 3px;
  padding-left: 14px;
  line-height: 1.4em;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: "›";
    position: absolute;
    left: 0;
  }

  ${media.mobile`
    margin-bottom: 10px;
  `};
`
