import { css, createGlobalStyle } from "styled-components"

export interface IGlobalStyle {
  useWoff2Font: boolean
  assetsPublicPath: string
}

export default createGlobalStyle<IGlobalStyle>`
  * {
    box-sizing: border-box;
  }
  html, body {
    margin: 0;
  }
  body {
    font-family: ${(props) => props.theme.baseFont};
  }
  input,
  textarea,
  select,
  button {
    font-size: 100%;
    font-family: inherit;
    padding: 0 12px;
    background: #fff;
    border: 1px solid ${(props) => props.theme.formInputBorderColor};
    border-radius: ${(props) => props.theme.borderRadius}px;
    height: 46px;
    &:focus {
      outline: 0;
      border-color: ${(props) => props.theme.buttonColor};
      box-shadow: 0 0 0 3px ${(props) => props.theme.buttonHighlightColor};
    }
  }
  a {
    color: ${(props) => props.theme.linkColor};
    text-decoration: underline;
    &:focus {
      outline: 0;
      border-radius: ${(props) => props.theme.borderRadius / 2}px;
      box-shadow: 0 0 0 3px ${(props) => props.theme.linkHighlightColor};
    }
    &:hover,
    &:active {
      color: ${(props) => props.theme.linkHoverColor};
      text-decoration: none;
    }
  }
  p {
    line-height: 1.4em;
    margin: 1em 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  h1, h2, h3 {
    font-family: ${(props) => props.theme.brandFont};
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  blockquote {
    margin: 1em 18px;
  }
  ul, ol {
    margin: 1em 0;
    padding: 0 0 0 18px;
    line-height: 1.4em;
  }
  li {
    margin: 5px 0;
  }

  ::-moz-selection {
    background: ${(props) => props.theme.selectionBackground};
  }
  ::selection {
    background: ${(props) => props.theme.selectionBackground};
  }
  ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
    color: #b3b3b3;
    opacity: 1;
  }
  
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="search"] {
    -webkit-appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-ms-clear {
    width: 0;
    height: 0;
  }
  ::-ms-reveal {
    width: 0;
    height: 0;
  }

  ${(props) =>
    props.theme.fonts &&
    props.theme.fonts.map(
      ({ family, file, woff2file, style = "normal", weight = "normal" }) => {
        const fontSources = []

        // we push woff2 first (if applicable) as the order of occurrence is followed in CSS
        if (props.useWoff2Font && woff2file) {
          fontSources.push(
            `url("${
              props.assetsPublicPath || ""
            }/fonts/${woff2file}") format("woff2")`
          )
        } else if (console) {
          // eslint-disable-next-line no-console
          console.warn(
            `woff2 font has not been enabled for the font-family ${family}. It is adviced to upgrade in order to save bytes.`
          )
        }

        fontSources.push(
          `url("${props.assetsPublicPath || ""}/fonts/${file}") format("woff")`
        )

        return css`
          @font-face {
            font-family: "${family}";
            src: ${fontSources.join(",")};
            font-style: ${style};
            font-weight: ${weight};
            font-display: fallback;
          }
        `
      }
    )}
`
