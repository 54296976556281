// Styleguide: https://www.figma.com/file/dSu6jC2HW993B8tPDgfUxp/Styleguide-Karwei.nl?node-id=1169%3A203

import { DefaultTheme } from "styled-components"

// see: https://material.io/design/color/the-color-system.html#tools-for-picking-colors
// styleguide colors mapped towards the material design approach
// as it will remain more flexible over time and allows for greater extension.

/** Brand colors  */
const white50 = "#FFF"
const black900 = "#111316" // styleguide: "blackish"
const black900o20 = "rgba(17, 19, 22, 0.2)" // black900 @ 0.2 opacity

/** Grey scale */
const grey50 = "#F1F4F8" // styleguide: grey 1
const grey200 = "#DCE1E8" // styleguide: grey 2
const grey400 = "#ABB3BF" // styleguide: grey 3
const grey500 = "#878E9C" // styleguide: grey 4
const grey700 = "#5B6573" // styleguide: grey 5
const grey800 = "#4B525B" // styleguide: grey 6

/** Call to action */
const orange900 = "#FD7D0A" // styleguide: CTA
const darkorange900 = "#C36231" // styleguide: CTA+

/** Messaging and validations */
const red700 = "#E55026" // styleguide: Error
const red900 = "#B93A16" // styleguide: Error+
const green500 = "#0BBA5F" // styleguide: Validation

/** Foundations spacing */
const gutterSmall = 5
const gutter = 10
const gutterMedium = 15
const gutterLarge = 30
const gutterXL = 50
const gutterXXL = 70
const gridGap = 20

const Theme: DefaultTheme = {
  name: "karwei_nl",
  domain: "karwei.nl",
  karwei: true,
  brandName: "KARWEI",
  contentfulSpaceId: "ufyps4xa8qu5",
  contentfulToken:
    "2441ab9b85cbb65b61520dcf6c4948ef4f7e07619837f16aba5be8096a85c2b7",
  paths: {
    termsAndConditions:
      "klantenservice/voorwaarden-veiligheid/algemene-voorwaarden",
    disclaimer: "klantenservice/voorwaarden-veiligheid/disclaimer",
    privacy: "klantenservice/voorwaarden-veiligheid/privacy",
    responsibleDisclosure:
      "klantenservice/voorwaarden-veiligheid/responsible-disclosure",
    returnRequirements:
      "klantenservice/ruilen-retourneren/retour-online-aankoop",
    customerservice: "klantenservice",
  },
  formulaLoyaltyCardName: "Club Karwei-kaart",
  loyaltyCardName: "Club Karwei-kaart",
  loyaltyCardSlug: "club-karwei-kaart",
  loyaltyPointsOverview: "gespaarde heitjes",
  loyaltyPointSlug: "Heitjes",
  giftcardName: "Cadeaukaart",
  giftcardSlug: "cadeaukaart",
  storeName: "bouwmarkt",
  storesName: "Bouwmarkten",
  storesSlug: "vestigingen",
  adviceName: "Advies",
  adviceSlug: "klusadvies",
  serviceName: "Klantenservice",
  serviceSlug: "klantenservice",
  servicePhone: "088 – 010 86 20",
  servicePhoneLink: "tel:+31880108620",
  promoName: "Actie",
  promoSlug: "acties-en-aanbiedingen",
  baseFont: "Akkurat, sans-serif",
  brandFont: "Akkurat, sans-serif",
  serifFont: "Mayfield, Georgia, serif",
  borderRadius: 4,
  primaryBackgroundColor: white50,
  secondaryBackgroundColor: grey50,
  pageBackgroundColor: grey50,
  pageBackgroundColorTransparent: `${grey50}00`,
  footerBackgroundColor: grey50,
  brandColor: black900,
  brandColorHighlight: black900o20,
  brandColorSecondary: white50,
  linkColor: black900,
  linkHoverColor: black900,
  linkHighlightColor: grey200,
  buttonColor: black900,
  buttonFocusBorderColor: darkorange900,
  buttonFocusColor: grey200,
  buttonBorderRadius: 300,
  buttonHighlightColor: grey200,
  primaryButtonColor: orange900,
  progressColor: green500,
  progressBackgroundColor: grey200,
  secondaryButtonColor: white50,
  secondaryButtonTextColor: black900,
  secondaryButtonFocusColor: grey50,
  dangerButtonColor: red700,
  dangerButtonFocusColor: red900,
  inputBackgroundColor: white50,
  badgeColor: grey800,
  badgeBackgroundColor: grey200,
  notificationColor: orange900,
  errorColor: red700,
  successColor: green500,
  mutedColor: grey700,
  borderColor: grey200,
  dividerColor: grey200,
  titleColor: black900,
  textColor: black900,
  iconColor: grey500,
  secondaryTextColor: grey700,
  searchPromotionColor: orange900,
  selectionBackground: "rgba(0, 143, 157, 0.25)",
  formInputFloatingLabelBackgroundColor: grey50,
  formInputFloatingLabelTextColor: grey800,
  formInputBorderColor: grey700,
  formInputBorderFocussedColor: grey500,
  formInputPlaceholderColor: grey700,
  formInputTextColor: black900,
  neutral50: grey50,
  neutral200: grey200,
  neutral400: grey400,
  neutral500: grey500,
  neutral700: grey700,
  neutral800: grey800,
  gutterSmall,
  gutter,
  gutterMedium,
  gutterLarge,
  gutterXL,
  gutterXXL,
  gridGap,
  fonts: [
    {
      family: "Akkurat",
      file: "Akkurat-Regular.woff",
      woff2file: "Akkurat-Regular.woff2",
    },
    {
      family: "Akkurat",
      file: "Akkurat-Bold.woff",
      woff2file: "Akkurat-Bold.woff2",
      weight: "bold",
    },
    { family: "Mayfield", file: "Mayfield.woff" },
  ],
}

export default Theme
